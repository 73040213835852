const React = require('react')
const ReactDOM = require('react-dom/client')
const { ContextProvider } = require('./src/context')

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
exports.onInitialClientRender = () => {
  const style = document.documentElement.style

  const sizeListener = () => {
    style.setProperty('--window-height', window.innerHeight + 'px')
    style.setProperty('--window-width', window.innerWidth + 'px')
  }
  sizeListener()
  window.addEventListener('resize', sizeListener)
}
exports.wrapRootElement = ({ element }) => <ContextProvider>{element}</ContextProvider>
